import React from 'react';
import * as styles from './GridLayout.module.css'

const GridLayout = ({ components = [] }) => {

    return (
        <div className={styles.gridLayout}>
            {components}
        </div>
    )
}

export default GridLayout;