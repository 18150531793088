import React from "react";
import * as styles from './GridHoverableImageElement.module.css'
import HandmadeStretchedText from "./HandmadeStretchedText";


const GridHoverableImageElement = ({ children, year, titleTop = "", titleBottom }) => {

    return (
        <div className={styles.outerContainer}>
            {children}
            <div className={styles.hoverable}>
                <div className={styles.titleCnt}>
                    <h4>{year}</h4>
                    <HandmadeStretchedText title={titleTop} />
                    {titleBottom ? <HandmadeStretchedText title={titleBottom} /> : null}
                </div>

            </div>
        </div>
    )
}

export default GridHoverableImageElement