import React, { useContext, useEffect } from "react";
import PageLayout from '../../components/PageLayout';
import GridLayout from '../../components/GridLayout';
import { GlobalDispatchContext } from "./../../context/GlobalContextProvider"
import { ARTIST_LAUYOUT } from "./../../context/consts";
import GridHoverableImageElement from "../../components/GridHoverableImageElement";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/Seo";


const CalligraphyPage = () => {

    const dispatch = useContext(GlobalDispatchContext);
    useEffect(() => dispatch({ type: "TOGGLE_THEME", theme: ARTIST_LAUYOUT }), [dispatch])

    const imageComponents = [
        <GridHoverableImageElement key="0" year="2019" titleTop="Leonardo" titleBottom="500px">
            <StaticImage src="./../../images/artist/calligraphy/01_2019_Calligraphy_Leonardo500px.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="1" year="2019" titleTop="Alien" titleBottom="bible">
            <StaticImage src="./../../images/artist/calligraphy/02_2019_Calligraphy_AlienBible.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="2" year="2018" titleTop="Pattern3d">
            <StaticImage src="./../../images/artist/calligraphy/03_2018_Calligraphy_3DPattern.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="3" year="2016" titleTop="Remember" titleBottom="thename">
            <StaticImage src="./../../images/artist/calligraphy/04_2016_Calligraphy_RemembertheName.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="4" year="2016" titleTop="sport" titleBottom="istheway">
            <StaticImage src="./../../images/artist/calligraphy/05_2016_Calligraphy_Sportistheway.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="5" year="2016" titleTop="italhymn" titleBottom="2.006">
            <StaticImage src="./../../images/artist/calligraphy/06_2016_Calligraphy_ItalyHymn2.006.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="6" year="2015" titleTop="Vfor" titleBottom="vendetta">
            <StaticImage src="./../../images/artist/calligraphy/07_2015_Calligraphy_VforVendetta.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="7" year="2015" titleTop="Lacalabre" titleBottom="sella">
            <StaticImage src="./../../images/artist/calligraphy/08_2015_Calligraphy_LaCalabresella.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="8" year="2015" titleTop="pulsar">
            <StaticImage src="./../../images/artist/calligraphy/09_2015_Calligraphy_Pulsar.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>,
        <GridHoverableImageElement key="9" year="2014" titleTop="thegallop">
            <StaticImage src="./../../images/artist/calligraphy/10_2015_Calligraphy_IlGaloppo.jpg" alt="" layout="fullWidth" /></GridHoverableImageElement>
    ];

    return (
        <PageLayout secondHead="Calligraphy" showBack={true} linkTo="/artist">
            <GridLayout components={imageComponents}></GridLayout>
            <Seo subTitle="calligraphy" />
        </PageLayout >
    )
}

export default CalligraphyPage